import React from 'react';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Chip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

function SlackList({ slackMembers, selectMember, sellers, filter }) {
  const classes = useStyles();

  const lowerCaseFilter = (filter === null ? '' : filter).toLowerCase();

  return (
    <List dense className={classes.root}>
      {slackMembers
        .filter((member) =>
          member.name.toLowerCase().includes(lowerCaseFilter) 
            || member.profile.real_name.toLowerCase().includes(lowerCaseFilter)
            || member.profile.display_name.toLowerCase().includes(lowerCaseFilter)
        )
        .map((member) => {
          const numberOfSellers = sellers.filter((seller) => seller.users.includes(member.id)).length;

          return (
            <ListItem key={member.id} button onClick={() => selectMember(member)}>
              <ListItemAvatar>
                <Avatar
                  alt={member.name}
                  src={member.profile.image_192}
                />
              </ListItemAvatar>
              <ListItemText primary={member.profile.real_name} secondary={member.name} />
              <ListItemSecondaryAction>
                {numberOfSellers > 0 && (
                  <Chip label={numberOfSellers} color="primary" />
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })
      }
    </List>
  );
}

export default SlackList;
