import React from 'react';
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Button,
  IconButton,
} from '@material-ui/core';

import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteIcon from '@material-ui/icons/Delete';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme)  => ({
  root: {
    width: '100%',
    minWidth: 250,
    backgroundColor: theme.palette.background.paper,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function SellersDrawer({
  isOpen,
  close,
  openNewSellerModal,
  deleteSeller,
  sellers,
  openCodeModal,
}) {
  const classes = useStyles();

  return (
    <Drawer
      anchor="left"
      open={isOpen}
      onClose={close}
    >
      <div className={classes.toolbar}>
        <Button 
          color="inherit"
          onClick={openNewSellerModal}
          startIcon={<PersonAddIcon />}
        >
          New Seller
        </Button>
      </div>
      <Divider />
      {sellers && (
        <List dense className={classes.root}>
          {sellers.map((seller) => {
            return (
              <ListItem key={seller.id} button onClick={() => openCodeModal(seller)}>
                <ListItemText primary={seller.name} />
                <ListItemSecondaryAction>
                  <IconButton onClick={() => deleteSeller(seller)}>
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      )}
    </Drawer>
  );
}

export default SellersDrawer;
