import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  Button,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

function SellerCode({ data }) {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  if (data === null) {
    return null;
  }

  const { code, seller } = data;

  const showTooltip = () => {
    setCopied(true);
    setTimeout(hideTooltip, 400);
  };
  
  const hideTooltip = () => setCopied(false);

  return (
    <div className={classes.root}>
      <Typography variant="h6" gutterBottom>{seller}</Typography>
      <ReactCodeInput
        type="number"
        fields={6}
        value={code}
        autoFocus={false}
      />
      <div>
        <CopyToClipboard
          text={code}
          onCopy={showTooltip}
        >
          <Tooltip 
            title="Copied"
            onClose={hideTooltip}
            open={copied}
            disableFocusListener
            disableHoverListener
            disableTouchListener
          >
            <Button
              color="primary"
              startIcon={<CopyIcon />}
            >
              Copy to clipboard
            </Button>
          </Tooltip>
        </CopyToClipboard>
      </div>
    </div>
  );
}

export default SellerCode;
