import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

function SimpleDialog({ dialog, close }) {
  if (!dialog) {
    return null;
  }

  return (
    <Dialog
      open={dialog !== null}
      onClose={close}
    >
      <DialogTitle id="alert-dialog-title">{dialog.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialog.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SimpleDialog;
