import React, { useState } from 'react';
import {
  TextField,
  Button,
} from '@material-ui/core';
import LoginIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 10,
  },
}));

function LoginForm({ login }) {
  const classes = useStyles();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    login(username, password);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Username"
        fullWidth
        onChange={(e) => setUsername(e.target.value)}
        margin="normal"
      />

      <TextField
        label="Password"
        fullWidth
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        margin="normal"
      />

      <Button 
        variant="contained"
        color="primary"
        type="submit"
        className={classes.button}
        startIcon={<LoginIcon />}
      >
        Login
      </Button>
    </form>
  );
}

export default LoginForm;
