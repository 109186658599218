import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

function ConfirmDialog({ confirmation }) {
  if (!confirmation) {
    return null;
  }

  return (
    <Dialog
      open={confirmation !== null}
      onClose={() => confirmation.cb(false)}
    >
      <DialogTitle id="confirm-dialog-title">{confirmation.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-dialog-description">
          {confirmation.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => confirmation.cb(false)} color="primary">
          Cancel
        </Button>
        <Button onClick={() => confirmation.cb(true)} color="primary">
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
