import React, { useState } from 'react';
import SearchBar from 'material-ui-search-bar';
import {
  Drawer,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SlackList from './SlackList';

const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function SlackDrawer(props) {
  const classes = useStyles();

  const [searchValue, setSearch] = useState('');
  
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar}>
        <SearchBar
          onChange={(value) => setSearch(value)}
          onCancelSearch={() => setSearch('')}
          value={searchValue}
        />
      </div>
      <Divider />
      <SlackList {...props} filter={searchValue} />
    </Drawer>
  );
}

export default SlackDrawer;
