import React, { useState } from 'react';
import {
  TextField,
  Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 10,
  },
}));

function AddSellerForm({ addSeller }) {
  const classes = useStyles();
  
  const [name, setName] = useState(null);
  const [secret, setSecret] = useState(null);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    addSeller(name, secret);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        label="Seller name"
        fullWidth
        onChange={(e) => setName(e.target.value)}
        margin="normal"
      />

      <TextField
        label="Secret"
        fullWidth
        onChange={(e) => setSecret(e.target.value)}
        margin="normal"
      />

      <Button
        variant="contained"
        color="primary"
        type="submit"
        className={classes.button}
        startIcon={<AddIcon />}
      >
        Add
      </Button>
    </form>
  );
}

export default AddSellerForm;
