/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import {
  CssBaseline,
  Typography,
  AppBar,
  Toolbar,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import PeopleIcon from '@material-ui/icons/People';
import LoginIcon from '@material-ui/icons/ExitToApp';
import LogoutIcon from '@material-ui/icons/Close';

import CustomModal from './components/CustomModal';
import SimpleDialog from './components/SimpleDialog';
import ConfirmDialog from './components/ConfirmDialog';

import LoginForm from './components/LoginForm';
import SellerCode from './components/SellerCode';

import SlackDrawer from './components/SlackDrawer';
import SlackMember from './components/SlackMember';

import SellersDrawer from './components/SellersDrawer';
import AddSellerForm from './components/AddSellerForm';

import './App.css';

const API_BASE = 'https://api.amazonbot.xyz';
const drawerWidth = 340;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

function App() {
  const classes = useStyles();

  // State
  const [token, setToken] = useState(null);
  const [dialog, setDialog] = useState(null);

  const [confirmation, setConfirmation] = useState(null);
  const [code, setCode] = useState(null);

  const [isCodeOpen, setCodeOpen] = useState(false);
  const [isLoginOpen, setLoginOpen] = useState(false);
  const [isSellersDrawerOpen, setSellersDrawerOpen] = useState(false);
  const [isNewSellerModalOpen, setNewSellerModalOpen] = useState(false);

  const [sellers, setSellers] = useState([]);
  const [slackMembers, setSlackMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);

  // Instantiate universal cookie
  const cookies = new Cookies();

  /**
   * Return the Authorization header
   *
   * @return {object}     An object containing the Authorization property
   */
  const getAuthHeader = () => ({ Authorization: `Bearer ${token}` });

  // Run on component mount or when the value of `token` changes
  useEffect(() => {
    // We need to use a IIFE because useEffect has to
    // return a clean-up function, so we can't use async
    // directly (which would return a Promise)
    (async () => {
      try {
        // Get token from cookie
        const tokenFromCookie = cookies.get('token');
        if (tokenFromCookie) {
          setToken(tokenFromCookie);    // cookie is set, set token in state
        } else {
          setLoginOpen(true);           // cookie is not set, open the login modal
        }
        
        if (token !== null) {
          await fetchSellers();         // `sellers` will be stored in state

          const response = await fetch(`${API_BASE}/slack/users`, {
            headers: getAuthHeader(),
          });

          if (response.status !== 200) {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
          }

          const { data } = await response.json();

          // Remove deleted users and bots from the members array
          const members = data
            .filter((member) => !member.deleted && !member.is_bot && member.id !== 'USLACKBOT');
          
          setSlackMembers([...slackMembers, ...members]);
        }
      } catch (error) {
        console.error(error);
        setError({ title: 'Could not fetch users', description: error.message });
      }
    })();
  }, [token]); // bind token state to useEffect

  /**
   * Fetch all Amazon sellers from our backend and store them in state
   *
   * @return {Promise}            A Promise that gets resolved when the sellers have been fetched
   */
  const fetchSellers = async () => {
    try {
      // GET /sellers
      const response = await fetch(`${API_BASE}/sellers`, {
        headers: getAuthHeader(),
      });
      
      if (response.status !== 200) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const { data } = await response.json();
      setSellers(data);
    } catch (error) {
      console.error(error);
      setError({ title: 'Could not fetch users', description: error.message });
    }
  };

  /**
   * Add new Amazon seller
   *
   * @param {string} name         Amazon seller name
   * @param {string} secret       Authentication secret
   * @return {Promise}            A Promise that gets resolved 
   *                              when the seller has been added
   */
  const addSeller = async (name, secret) => {
    try {
      // PUT /sellers
      const response = await fetch(`${API_BASE}/sellers`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, secret }),
      });

      if (response.status !== 200) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      // If we got an HTTP 200 response, we can close the seller modal
      setNewSellerModalOpen(false);

      // Re-fetch sellers to reflect the changes
      await fetchSellers();
    } catch (error) {
      console.error(error);
      setError({ title: 'Could not fetch users', description: error.message });
    }
  };

  /**
   * Login with the given username and password
   *
   * @param {string} username     The username
   * @param {string} password     The password
   * @return {Promise}            A Promise that gets resolved when we are logged in
   */
  const login = async (username, password) => {
    try {
      // POST /auth/login
      const response = await fetch(`${API_BASE}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const { status, statusText } = response;

      if (status === 200 || status === 401) {
        const data = await response.json();

        if (status === 200 && data.success && data.token) {
          cookies.set('token', data.token, { path: '/' });
          setToken(data.token);
          setLoginOpen(false);
          return;
        }

        if (status === 401 && !data.success && data.error) {
          throw new Error(data.error);
        }

        throw new Error(`Error ${status}: ${statusText}`);
      } else {
        throw new Error(`Error ${status}: ${statusText}`);
      }
    } catch (error) {
      console.error(error);
      setError({ title: 'Could not login', description: error.message });
    }
  };

  /**
   * Logout
   * Remove the token from state, remove the token cookie and reload
   */
  const logout = () => {
    setToken(null);
    cookies.remove('token');
    window.location.reload();
  };

  /**
   * Change the permission of the member with the given id for the seller with the given id
   *
   * @param {string} memberId           The id of the Slack member
   * @param {string|number} sellerId    The id of the Amazon seller
   * @param {boolean} grantAccess       Whether we want to grant or revoke the access
   *                                    (`true` means grant access, `false` means revoke)
   * @return {Promise}                  A Promise that gets resolved when the permissions
   *                                    have changed
   */
  const changePermission = async (memberId, sellerId, grantAccess) => {
    try {
      // PUT or DELETE /sellers/access
      await fetch(`${API_BASE}/sellers/access`, {
        method: grantAccess ? 'put' : 'delete',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ memberId, sellerId }),
      });

      // Re-fetch sellers to reflect the changes
      await fetchSellers();
    } catch (error) {
      console.error(error);
      setError({ title: 'Could not change permission', description: error.message });
    }
  };

  /**
   * Delete seller
   *
   * @param {object} seller             The seller object
   * @param {string|number} seller.id   The seller id
   * @param {string} seller.name        The seller name
   * @return {Promise}                  A Promise that gets resolved when
   *                                    the seller has been deleted
   */
  const deleteSeller = async ({ id, name }) => {
    try {
      // DELETE /sellers/:sellerId
      await fetch(`${API_BASE}/sellers/${id}`, {
        method: 'delete',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Re-fetch sellers to reflect the changes
      await fetchSellers();
    } catch (error) {
      console.error(error);
      setError({ title: 'Could not delete seller', description: error.message });
    }
  };

  const setError = (error) => setDialog(error);
  const openCodeModal = async (seller) => {
    try {
      const { id, name } = seller;

      // GET /sellers/:sellerId/code
      const response = await fetch(`${API_BASE}/sellers/${id}/code`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }

      const data = await response.json();

      setCode({
        seller: name,
        code: data.code,
      });

      setCodeOpen(true);
    } catch (error) {
      console.error(error);
      setError({ title: 'Could not generate code', description: error.message });
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            AuthBot dashboard
          </Typography>

          <Button 
            color="inherit"
            onClick={() => setSellersDrawerOpen(true)}
            startIcon={<PeopleIcon />}
          >
            Sellers
          </Button>

          {token ? (
            <Button 
              color="inherit"
              onClick={logout}
              startIcon={<LogoutIcon />}
            >
              Logout
            </Button>
          ) : (
            <Button 
              color="inherit" 
              onClick={() => setLoginOpen(true)}
              startIcon={<LoginIcon />}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <SlackDrawer
        slackMembers={slackMembers}
        selectMember={(member) => setSelectedMember(member)}
        sellers={sellers}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {selectedMember && (
          <SlackMember 
            member={selectedMember} 
            sellers={sellers}
            changePermission={changePermission}
          />
        )}
      </main>

      <SimpleDialog
        dialog={dialog}
        close={() => setDialog(null)}
      />

      <ConfirmDialog confirmation={confirmation} />

      <SellersDrawer
        isOpen={isSellersDrawerOpen}
        close={() => setSellersDrawerOpen(false)}
        openNewSellerModal={() => setNewSellerModalOpen(true)}
        deleteSeller={(seller) => {
          setConfirmation({
            title: 'Delete seller?',
            description: `This will permanently delete '${seller.name}'. This action cannot be undone.`,
            cb: (response) => {
              if (response) {
                deleteSeller(seller);
              }
              setConfirmation(null);
            },
          });
        }}
        openCodeModal={openCodeModal}
        sellers={sellers}
      />

      <CustomModal
        isOpen={isNewSellerModalOpen}
        close={() => setNewSellerModalOpen(false)}
        component={() => <AddSellerForm addSeller={addSeller} />}
      />

      <CustomModal
        isOpen={isLoginOpen}
        close={() => setLoginOpen(false)}
        component={() => <LoginForm login={login} />}
      />

      <CustomModal
        isOpen={isCodeOpen}
        close={() => {
          setCodeOpen(false);
          setCode(null);
        }}
        component={() => <SellerCode data={code} />}
      />
    </div>
  );
}

export default App;
