import React from 'react';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 480,
  },
  media: {
    height: 220,
  },
  list: {
    width: '100%',
    minWidth: 400,
  },
}));

function SlackMember({ member, sellers, changePermission }) {
  const classes = useStyles();
  console.log(sellers);

  return (
    <Card className={classes.card}>
      <CardMedia
        className={classes.media}
        image={member.profile.image_512}
        title={member.name}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {member.profile.real_name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Manage permissions
        </Typography>

        <List dense className={classes.list}>
          {sellers.map((seller) => {
            const hasAccess = seller.users.includes(member.id);
            return (
              <ListItem key={seller.id}>
                <ListItemText primary={seller.name} />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={() => changePermission(member.id, seller.id, !hasAccess)}
                    checked={hasAccess}
                    inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </CardContent>
    </Card>
  );
}

export default SlackMember;
